export const subjectAbility = {
  General: 'General', // general ability, everyone can access
  Setting: 'Setting', // general ability, everyone can access
  Dashboard: 'Dashboard',
  Md: 'Md',
  MdCoa: 'MdCoa',
  MdCashbank: 'MdCashbank',
  ModuleApprobal: 'ModuleApprobal',
  MdCOAMainGroupActivity: 'MdCOAMainGroupActivity',
  MdCOAMainActivity: 'MdCOAMainActivity',
  MdCOAActivity: 'MdCOAActivity',
  MdElementMainGroupActivity: 'MdElementMainGroupActivity',
  MdElementMainActivity: 'MdElementMainActivity',
  MdElementActivity: 'MdElementActivity',
  MdContact: 'MdContact',
  MdEmployee: 'MdEmployee',
  MdUser: 'MdUser',
  MdUnit: 'MdUnit',
  MdWorkUnit: 'MdWorkUnit',
  MdProduct: 'MdProduct',
  MdProductBrand: 'MdProductBrand',
  MdProductCategory: 'MdProductCategory',
  MdProductSubCategory: 'MdProductSubCategory',
  MdPrefix: 'MdPrefix',
  MdIntergration: 'MdIntergration',
  MdIntergrationCat: 'MdIntergrationCat',
  MdActivityIntegration: 'MdActivityIntegration',
  Seaport: 'Seaport',
  Airport: 'Airport',
  Tx: 'Tx',
  TxCashbank: 'TxCashbank',
  TxCashbankRequest: 'TxCashbankRequest',
  TxCashbankAddIn: 'TxCashbankAddIn',
  TxCashbankAddOut: 'TxCashbankAddOut',
  TxCashbankAddTransfer: 'TxCashbankAddTransfer',
  TxCashbankRekon: 'TxCashbankRekon',
  TxJournal: 'TxJournal',
  TxApproval: 'TxApproval',
  TxDraftJournal: 'TxDraftJournal',
  TxCashAdvanceRequest: 'TxCashAdvanceRequest',
  TxCashAdvanceRequestApproval: 'TxCashAdvanceRequestApproval',
  TxCashAdvance: 'TxCashAdvance',
  TxSalesInvoice: 'TxSalesInvoice',
  TxSalesPayment: 'TxSalesPayment',
  TxPurchaseRequest: 'TxPurchaseRequest',
  TxPurchaseRequestMonitoring: 'TxPurchaseRequestMonitoring',
  TxPurchaseRequestOutstanding: 'TxPurchaseRequestOutstanding',
  TxPurchaseRequestAssignVendor: 'TxPurchaseRequestAssignVendor',
  TxPurchaseRequestAssignPrice: 'TxPurchaseRequestAssignPrice',
  TxPurchaseRequestChooseVendor: 'TxPurchaseRequestChooseVendor',
  TxPurchaseRequestPaymentAdvance: 'TxPurchaseRequestPaymentAdvance',
  TxPurchaseOrder: 'TxPurchaseOrder',
  TxApPosting: 'TxApPosting',
  TxApCashAdvance: 'TxApCashAdvance',
  TxPurchaseInvoice: 'TxPurchaseInvoice',
  TxPurchasePaymentVoucher: 'TxPurchasePaymentVoucher',
  TxPurchasePayment: 'TxPurchasePayment',
  TxGoodsRecipient: 'TxGoodsRecipient',
  TxFormPaymentAdvance: 'TxFormPaymentAdvance',
  TxFormPaymentVendor: 'TxFormPaymentVendor',
  TxFormPayemntAgent: 'TxFormPayemntAgent',
  TxClosing: 'TxClosing',
  TxChBacc: 'TxChBacc',
  TxBacc: 'TxBacc',
  TxBudgeting: 'TxBudgeting',
  TxBeginningBalance: "TxBeginningBalance",
  Report: 'Report',
  ReportLedger: 'ReportLedger',
  ReportCashAdvance: 'ReportCashAdvance',
  ReportCashAdvanceDetail: 'ReportCashAdvanceDetail',
  ReportSalesInvoice: 'ReportSalesInvoice',
  ReportCashBank: 'ReportCashBank',
  ReportPurchaseAging: 'ReportPurchaseAging',
  ReportPurchaseAgingDetail: 'ReportPurchaseAgingDetail',
  ReportPurchaseInvoice: 'ReportPurchaseInvoice',
  ReportPurchaseInvoiceDetail: 'ReportPurchaseInvoiceDetail',
  ReportTrialBalance: 'ReportTrialBalance',
  ReportWorkSheet: 'ReportWorkSheet',
  ReportPayment: 'ReportPayment',
  ReportProfitLoss: 'ReportProfitLoss',
  ReportJournal: 'ReportJournal',
  ReportMonthlyMutation: 'ReportMonthlyMutation',
  BaccTransaction: 'BaccTransaction',
  TxApPayment: 'TxApPayment',
  ReportPaymentAgingDetail: 'ReportPaymentAgingDetail',
  Reportesimation: 'Reportesimation',
  TxCashbankRequestMenu: 'TxCashbankRequestMenu',
  TxCashbankMenu: 'TxCashbankMenu',
  ReportCashBankSummary: 'ReportCashBankSummary',
  ReportBudgetMonth: 'ReportBudgetMonth',
  ReportBalanceSheet: 'ReportBalanceSheet',
  TxFormPaymentAgent: 'TxFormPaymentAgent',
  Trackings: 'Trackings',
  MonitoringAgent: 'MonitoringAgent',
  MonitoringChRate: 'MonitoringChRate',
  TxChGenerate: 'TxChGenerate',
  ReportCHCheckPoint: 'ReportCHCheckPoint',
  ReportARCheckPointAr: 'ReportARCheckPointAr',
  ReportChDetail: 'ReportChDetail',
  ReportAgingChV1: 'ReportAgingChV1',
  ReportAgingCH: 'ReportAgingCH',
  ReportChBadPayor: 'ReportChBadPayor',
  ReportSummarryCH: 'ReportSummarryCH',
  ReportSummaryCHBACC: 'ReportSummaryCHBACC',
  MonitoringAgents: 'MonitoringAgents',
  MonitoringChAll: 'MonitoringChAll',
  MonitoringCh: 'MonitoringCh',
  TxChBaccReceivePayment: 'TxChBaccReceivePayment',
  TxBaccPayment: 'TxBaccPayment',
  ReportMsterPR: 'ReportMsterPR',
  ReportCashAndBank: 'ReportCashAndBank',
  ReportDetailCashbank: 'ReportDetailCashbank',
  ReportAgingPr: "ReportAgingPr",
  ReportActualBudget: "ReportActualBudget",
  ReportActualStd: "ReportActualStd",
  ReportBalanceSheetComparison: "ReportBalanceSheetComparison",
  MdTax: "MdTax",
  MdJournalTemplate: "MdJournalTemplate",
  MdOffice: "MdOffice",
  MdTag: "MdTag"
}
